import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Container, Gutter, Description, ColumnContainer, Title } from '../stylesheets/components';
import { Header, PublicationLists, NewsArticle } from '../components';
import styled from 'styled-components';
import { API_URL } from '../constants/defaults'
import _ from 'lodash';
import media from '../stylesheets/media';

const BACKGROUND_COLOR = "#222222";
const FOREGROUND_COLOR = "#FFFFFF";


const LinkContainer = styled(Container)`
  min-height: auto;
`;

const Column = styled.div`
  width: calc(50% - 10px);

  ${media.mobileLarge`
    width: 100%;
  `}
`;

const PublicationArea = styled.div`

  width: calc(50% - 20px);

  div.wrapper {
/* 
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; */

  }

  ${media.mobileLarge`
    width: 100%;
  `}
`;

class Publications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      links: []
    };
  }
  componentDidMount() {
    this.loadData();
  }

  async loadData() {

    const response = await fetch(`${API_URL}/links?_sort=Title:ASC`, { method: "get" });
    if (response.ok) {
      let data = await response.json();
      this.setState({
        links: data
      });

    } else {
      let data = await response.json();
      console.log(data);
    }
  }

  render() {
    let { links } = this.state;

    return (
      <LinkContainer style={{ backgroundColor: BACKGROUND_COLOR }}>
        <a name="links"></a>
        <Gutter h={25} />
        <ColumnContainer>
          <Column>
            <Title color={FOREGROUND_COLOR}>
              Feminist / Anti-racist / Decolonial / Indigenous / Queer / Abolitionist / Global South Data Research Groups Who Inspire Us
            </Title>
            <Description color="#999">
              <p>
                There are many research labs and groups, inside and outside academia, who are mobilizing visionary theories and participatory methods to demonstrate how data science can work towards justice, care and co-liberation. Here are some of the groups that have been an inspiration for us at the Data + Feminism Lab. 
              </p>
              <p>
                Does your group work on data science with a feminist, anti-racist, decolonial, Indigenous, queer, Global South and/or abolitionist perspective and want to see your group listed here? <a href="mailto:dataplusfeminism@mit.edu">Get in touch!</a>
              </p>
            </Description>
          </Column>
          <Column>

            <Description color="#999">
              {
                _.map(links, link => {
                  return(
                    <p>
                      <span style={{ color: FOREGROUND_COLOR}}>{ link.Title }</span><br/>
                      <a href={link.url} rel="noopener noreferrer" target="_blank">{link.url}</a>
                    </p>
                  )
                })
              }
            </Description>
          </Column>
        </ColumnContainer>
        <Gutter h={25} />
      </LinkContainer>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowHeight: state.windowHeight
  };
}

export default connect(mapStateToProps)(Publications);