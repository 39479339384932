export const windowResize = (dimension) => {
  return {
    type: 'WINDOW_RESIZE',
    payload: {
      windowWidth: dimension.width,
      windowHeight: dimension.height
    }
  }
}

export const changeSelectedPerson = (selectedPerson) => {
  return {
    type: 'CHANGE_SELECTED_PERSON',
    payload: {
      selectedPerson: selectedPerson
    }
  }
}

export const changeAboutContent = (aboutContent) => {
  return {
    type: 'CHANGE_ABOUT_CONTENT',
    payload: {
      aboutContent: aboutContent
    }
  }
}

export const changeCurrentEvent = (currentEvent) => {
  return {
    type: "CHANGE_CURRENT_EVENT",
    payload: {
      currentEvent: currentEvent
    }
  }
};