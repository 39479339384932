import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Container, Gutter } from '../stylesheets/components';
import styled from 'styled-components';
import { Logo } from '../components'
import { TITLE_TYPE } from '../stylesheets/mixins';
import { scaleLinear } from 'd3';
import ReactMarkdown from 'react-markdown';
import { TweenMax, Power3 } from 'gsap';
import media from '../stylesheets/media';
import { withRouter } from "react-router-dom";

const IntroContainer = styled(Container)`
  min-height: 800px;
  height: calc(100vh - 20px);
`;

const IntroHeader = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.mobileLarge`
    display: block;
  `}
`;

const LogoArea = styled.div`
  width: calc(50% - 10px);

  ${media.mobileLarge`
    width: 100%;
  `}
`;

const DescArea = styled.div`

  width: calc(50% - 10px);
  ${media.mobileLarge`
    width: 100%;
    font-size: 0.9em;
    margin-top: 10px;
    line-height: 1.3;
  `}
`;

const Slogan = styled.div`
  p {
    ${TITLE_TYPE}
    max-width: 800px;
    font-size:2.9em;

    ${media.bigDesktop`
      max-width: 600px;
      font-size:2.5em;
    `}

    ${media.mobileLarge`
      font-size: 1.7em;
    `}


    em {
      font-style: italic;
    }
  }
  
`;

const Button = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  display: inline;
  cursor: pointer;
  font-size: 20px;
  transition: 0.4s all;
  &:hover {
    opacity: 0.6;
  }

    ${media.mobileLarge`
      font-size: 17px;
    `}

  
`;


class Intro extends Component {

  handleClick(e) {

    var d = document.querySelector(`a[name=${e}]`);

    var bodyRect = document.body.getBoundingClientRect(),
      elemRect = d.getBoundingClientRect(),
      pos = elemRect.top - bodyRect.top;

    TweenMax.to((document.scrollingElement || document.documentElement), 1, { ease: Power3.easeInOut, scrollTop: pos - 10 });

    // this.props.history.push(`/${e}`);
  }


  render() {
    let { windowHeight, aboutContent } = this.props;
    let gutterScale = scaleLinear().domain([768, 2000]).range([120, 400]).clamp(true);
    return (
      <IntroContainer>
        
        <IntroHeader>
          <LogoArea>
            <Logo color="#000000" />
          </LogoArea>
          <DescArea>
            Data + Feminism Lab<br/>
            Department of Urban Studies and Planning <br/>
            Massachusetts Institute of Technology

            <Gutter h={30} />
            —<br/>
            <Button onClick={this.handleClick.bind(this, "about-the-lab")}>About the lab</Button><br/>
            <Button onClick={this.handleClick.bind(this, "people")}>People</Button><br/>
            <Button onClick={this.handleClick.bind(this, "projects")}>Projects</Button><br/>
            <Button onClick={this.handleClick.bind(this, "events")}>Events</Button><br/>
            <Button onClick={this.handleClick.bind(this, "courses")}>Courses</Button><br/>
            <Button onClick={this.handleClick.bind(this, "publications")}>Publications</Button><br/>
            <Button onClick={this.handleClick.bind(this, "blog")}>Blog</Button><br/>
            <Button onClick={this.handleClick.bind(this, "links")}>Inspirations</Button>
      
            <Gutter h={gutterScale(windowHeight)} />

            —<br />
            <Slogan>

              <ReactMarkdown linkTarget='_blank' source={aboutContent.bigtext} />
            </Slogan>
            <Gutter h={20} />
          </DescArea>
        </IntroHeader>
      </IntroContainer>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowHeight: state.windowHeight,
    aboutContent: state.aboutContent
  };
}

export default withRouter(connect(mapStateToProps)(Intro));