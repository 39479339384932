const ABSOLUTE_MIDDLE = `
  transform: translate(0, -50%);
`;

const ABSOLUTE_CENTER = `
  transform: translate(-50%, 0);
`;

const ABSOLUTE_CENTER_MIDDLE = `
  transform: translate(-50%, -50%);
`;

const TITLE_TYPE = `
  font-family: "Domaine Display", sans-serif;
  font-size: 45px;
  line-height: 1.2;
`;

export {
  ABSOLUTE_CENTER,
  ABSOLUTE_CENTER_MIDDLE,
  ABSOLUTE_MIDDLE,
  TITLE_TYPE
};