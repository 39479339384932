import React, { Component } from 'react'
import styled from 'styled-components';
import { toTitleCase } from '../utils';
import _ from 'lodash';
import media from '../stylesheets/media';

const Column = styled.div`
  /* width: calc(50% - 20px); */
  margin-bottom: 100px;
  ${media.mobileLarge`
    margin-bottom: 30px;
  `}

`;

const Title = styled.div`
  color: ${props => props.color};
  margin-bottom: 20px;
`;

const PublicationArea = styled.div`
  color: #B5B5B5;
  margin-bottom: 20px;
    max-width: 450px;

  ${media.mobileLarge`
    font-size:0.9em;
    line-height: 1.3;
  `}

  span.white {
    color: ${props => props.color};
    text-decoration: underline;
  }
  a {
  }
`;


class PublicationLists extends Component {
  render() {
    let { category, publications } = this.props;
    let sortedList = _.sortBy(publications, p => p.publish_year).reverse();
    let categoryName = toTitleCase(category.replace(/_/g, " "));
    return (
      <Column>
        <Title color={this.props.foregroundColor}>
          {categoryName} ({publications.length})
        </Title>

        {
          _.map(sortedList, publication => {
            return (
              <PublicationArea color={this.props.foregroundColor} key={publication.id}>
                {publication.authors} ({publication.publish_year}). <a href={publication.link} rel="noopener noreferrer" target="_blank"><span className="white">{publication.title}</span></a>. {publication.location ? `${publication.location}: ` : ""}{publication.publisher}.
              </PublicationArea>
            )
          })
        }
        
      </Column>
    )
  }
}

export default PublicationLists;