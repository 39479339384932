import React, { Component } from 'react';
import { connect } from 'react-redux';
import { windowResize } from '../actions';
import { Intro, About, People, Projects, Courses, Publications, Events, EventShow, Blog, Links } from './';
import { Footer } from '../components';
import { CircleDrawer } from '../components';
import styled from 'styled-components';

import {
  Switch,
  Route
} from "react-router-dom";

const Fragment = React.Fragment;
const AllContainer = styled.div`
  position: relative;

`;


class Home extends Component {

  componentWillMount(){
    window.addEventListener('resize', this.resizeHandler.bind(this));
    this.resizeHandler();
  }

  componentDidMount(){

  }

  resizeHandler(e){
    this.props.dispatch(windowResize({
      width: window.innerWidth,
      height: window.innerHeight
    }));
    
  }


  render() {
    return (
      <Fragment>  
        <Switch>
          <AllContainer>
            <Route exact path="/">
              <CircleDrawer />
              <Intro />
              <About />
              <People />
              <Projects />
              <Events />
              <Courses />
              <Publications />
              <Blog />
              <Links />
              <Footer />
            </Route>

            <Route exact path="/events/:id">
              <EventShow />
              <Footer />
            </Route>
          </AllContainer>
        </Switch>
      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
  }
}

export default connect(mapStateToProps)(Home);