import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Course } from '../components';
import { Container, Gutter, ColumnContainer, Column } from '../stylesheets/components';
import { Header } from '../components'
import { API_URL } from '../constants/defaults'
import _ from 'lodash';

const BACKGROUND_COLOR = "#7819A5";
const FOREGROUND_COLOR = "#EBFF00";
class Courses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: []
    };
  }
  componentDidMount() {
    this.loadData();
  }

  async loadData() {

    const response = await fetch(`${API_URL}/classes?public=true`, { method: "get" });
    if (response.ok) {
      let data = await response.json();

      for (let i = 0; i < data.length; i++) {
        let split_str = data[i].Semester.split(" ");
        data[i].semester_season = split_str[0];
        data[i].semester_year = Number(split_str[1]);
      }

      let courses = _.orderBy(data, ['semester_year', 'semester_season'], ['desc', 'desc'])
      debugger;
      this.setState({
        courses: courses
      });

    } else {
      let data = await response.json();
      console.log(data);
    }
  }

  render() {
    let { courses } = this.state;
    return (
      <Container style={{ backgroundColor: BACKGROUND_COLOR }}>
        <a name="courses"></a>
        <Header backgroundColor={BACKGROUND_COLOR} foregroundColor={FOREGROUND_COLOR} current="Courses" />

        <Gutter h={50} />

        {
          _.map(courses, course => {
            return <Course {...course} key={course.id} backgroundColor={BACKGROUND_COLOR} color={FOREGROUND_COLOR} />
          })
        }
      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowHeight: state.windowHeight
  };
}

export default connect(mapStateToProps)(Courses);