import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { ColumnContainer, Column, Gutter, TitleA, Description } from '../stylesheets/components';
import ReactMarkdown from 'react-markdown';
import { changeCurrentEvent } from '../actions';
import moment from 'moment';

const EventDesc = styled(Description)`
  p {
    margin-bottom: 0;
    padding-bottom: 10px;
  }
`;

const Affi = styled.div`
  font-size: 17px;
  color: ${props => props.color};
`;


const EventColumnContainer = styled(ColumnContainer)`
  padding-bottom: 30px;
`

const MoreInfoLink = styled.a`
  color: ${props => props.color};
  font-size:0.8em;
  text-decoration: underline;
`;

const EventColumn = styled(Column)`
  width: 100%;
  border-top: ${props => props.isAlwaysOpen ? "none" : `1px solid ${props.color}`};
`;

class Event extends Component {
  computeEventDate(){
    let { event_start_at, event_end_at } = this.props;

    let eventStartAtMoment = moment(event_start_at);
    let eventEndAtMoment = moment(event_end_at);
  

      if (eventStartAtMoment.year() === eventEndAtMoment.year()) {

        if (eventStartAtMoment.month() === eventEndAtMoment.month()) {

          if (eventStartAtMoment.day() === eventEndAtMoment.day()) {

            return `${eventStartAtMoment.format("MMMM Do, YYYY, h:mma")}-${eventEndAtMoment.format("h:mma")}`;

          } else {

            return `${eventStartAtMoment.format("MMMM Do")}-${eventEndAtMoment.format("Do")}, ${eventEndAtMoment.format("YYYY")}`;

          }
        
        } else {

          return `${eventStartAtMoment.format("MMMM Do")}-${eventEndAtMoment.format("MMMM Do")}, ${eventEndAtMoment.format("YYYY")}`;

        }
      } else {


        return `${eventStartAtMoment.format("MMMM Do, YYYY")}-${eventEndAtMoment.format("MMMM Do, YYYY")}}`;

      }

   

  }

  handleClick(e){
    let { isAlwaysOpen, currentEvent } = this.props;

    if (!isAlwaysOpen){
      e.preventDefault();
      if (currentEvent === this.props.id) {
        this.props.dispatch(changeCurrentEvent(null));
      } else {
        this.props.dispatch(changeCurrentEvent(this.props.id));
      }
      
    }
    
  }

  render() {
    let { currentEvent, isAlwaysOpen } = this.props;

    let isOpen = isAlwaysOpen ? true : (currentEvent === this.props.id);

    return (
      <EventColumnContainer>
        <EventColumn color={this.props.color} isAlwaysOpen={isAlwaysOpen}>
          <TitleA to={`/events/${this.props.id}`} color={this.props.color} onClick={this.handleClick.bind(this)}>
            {this.props.title}
          </TitleA>
          <AnimatePresence initial={false}>
            {
              isOpen && 
              <motion.div 
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1, height: "auto" },
                  collapsed: { opacity: 0, height: 0 }
                }}
                transition={{ duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                <Affi color={this.props.color}>
                  {this.props.venue}
                </Affi>
                <Affi color={this.props.color}>
                  {this.computeEventDate()}
                </Affi>
                <Gutter h={30} />

                <EventDesc color={this.props.color}>
                  <ReactMarkdown linkTarget='_blank' source={this.props.description} />
                </EventDesc>

                {
                  this.props.url ? 
                  <MoreInfoLink href={this.props.url} color={this.props.color}>
                    For more information
                  </MoreInfoLink> : null
                }
              </motion.div>
            }  
          </AnimatePresence>
         
          
        </EventColumn>
      </EventColumnContainer>
    )
  }
}

let mapStateToProps = state => {
  return {
    currentEvent: state.currentEvent
  }
}
export default connect(mapStateToProps)(Event);