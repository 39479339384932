import React, { Component } from 'react'
import styled from 'styled-components';
import media from '../stylesheets/media';

const PublicationArea = styled.div`
  color: #B5B5B5;
  margin-bottom: 20px;
    max-width: 450px;

  ${media.mobileLarge`
    font-size:0.9em;
    line-height: 1.3;
  `}

  span.white {
    color: ${props => props.color};
    text-decoration: underline;
  }
  a {
    color: ${props => props.color};
    text-decoration: underline;
  }
`;

export default class NewsArticle extends Component {
  render() {
    return (
      <PublicationArea color={this.props.foregroundColor} key={this.props.id}>
        <a href={this.props.link} target="_blank" rel="noopener noreferrer">{ this.props.title } </a><br/>
        { this.props.publisher }, { this.props.news_date }
      </PublicationArea>
    )
  }
}
