export const DEFAULT_STATE = {
  windowWidth: 1024,
  windowHeight: 768,
  aboutContent: {
    id: 1,
    bigtext: "The Data + Feminism Lab uses data and computational methods to work towards *gender and racial equity*, particularly as they relate to *space and place*.",
    description: "Our work is based on the intersectional, anti-oppression approach outlined in Data Feminism (D'Ignazio & Klein, MIT Press, 2020) and we are proud members of the Design Justice Network. We also stand in solidarity with movements that seek to abolish data science that perpetuates oppression and those who are mobilizing data in the service of health, equity and justice. These include Data for Black Lives and Indigenous Data Sovereignty. The Data + Feminism Lab is based in the Department of Urban Studies and Planning at MIT and directed by Catherine D'Ignazio.",
    created_at: "2020-02-05T21:55:12.029Z",
    updated_at: "2020-02-05T22:07:53.610Z"
  },
  selectedPerson: null,
  currentEvent: null
};

export const API_URL = "https://api-web.dataplusfeminism.mit.edu"