import React, { Component } from 'react';
import styled from 'styled-components';
import { ColumnContainer, Column, Gutter, Title, Description, Credit } from '../stylesheets/components';
import ReactMarkdown from 'react-markdown';
import { API_URL } from '../constants/defaults';
import media from '../stylesheets/media';
import { linkRenderer } from '../utils';

const ProjectColumnContainer = styled(ColumnContainer)`
  margin-bottom: 100px;
`
const URLLink = styled.a`
  font-size: 17px;
  color: ${props => props.color};
  text-decoration: underline;
`;

const PhotoColumn = styled(Column)`
  overflow-x: hidden;
`;

const PictureArea = styled.div`
  background-color: ${props => props.backgroundColor};
  /* border-radius:400px; */
  overflow:hidden;

  ${media.mobileLarge`
    margin-top: 20px;
  `}
`;



const ExamplePhoto = styled.img`
  transition: 0.5s all;
  width: 100%;
  mix-blend-mode: multiply;
  -webkit-filter: grayscale(100%) contrast(1.2);
  filter: grayscale(100%) contrast(1);

  ::after{
    background-color: #23278a;
    mix-blend-mode: lighten;
  }

  &:hover {
    filter: none;
    -webkit-filter: none;
    mix-blend-mode: unset !important;
    background: transparent;
  }
`;

const Subtitle = styled.div`
  font-size:0.9em;
  line-height: 1.2;
  max-width: 600px;
`;

export default class Project extends Component {
  render() {
    return (
      <ProjectColumnContainer>
        <Column>
          <Title color={this.props.color}>
            {this.props.title}
          </Title>
          {
            this.props.subtitle ?   
            <Subtitle>
              { this.props.subtitle }
            </Subtitle> : null
          }
          
          {
            this.props.link ? 
            <URLLink href={this.props.link} target="_blank" color={this.props.color}>
              {this.props.link}
            </URLLink> : null
          }
          
          <Gutter h={30} />
          <Description color={this.props.color}>
            <ReactMarkdown linkTarget='_blank' source={this.props.description} escapeHtml={false} />
          </Description>
        </Column>
        <Column>
          {
            this.props.photo ? 
            <PictureArea backgroundColor={this.props.backgroundColor}>
              <ExamplePhoto src={`${API_URL}${this.props.photo.url}`} alt={`An example of ${this.props.title} project`} />
              <Credit color={this.props.color}>
                <ReactMarkdown linkTarget='_blank' source={this.props.photo_credit} escapeHtml={false} />
              </Credit>
            </PictureArea> : null
          }
        </Column>
      </ProjectColumnContainer>
    )
  }
}
