import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Project } from '../components';
import { Container, Gutter, Description, Title } from '../stylesheets/components';
import { Header } from '../components'
import { ColumnContainer, Column } from '../stylesheets/components';
import { API_URL } from '../constants/defaults'
import _ from 'lodash';

const BACKGROUND_COLOR = "#F7C8D5";
const FOREGROUND_COLOR = "#000000";
const Fragment = React.Fragment;
class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: []
    };
  }
  componentDidMount() {
    this.loadData();
  }

  async loadData() {

    const response = await fetch(`${API_URL}/projects?public=true`, { method: "get" });
    if (response.ok) {
      let data = await response.json();
      this.setState({
        projects: data
      });

    } else {
      let data = await response.json();
      console.log(data);
    }
  }

  render() {
    let { projects } = this.state;

    // let labProjects = _.filter(projects, p => p.project_type === "lab");
    // let studentProjects = _.filter(projects, p => p.project_type === "student");

    return (
      <Container style={{ backgroundColor: BACKGROUND_COLOR }}>
        <a name="projects"></a>
        <Header backgroundColor={BACKGROUND_COLOR} foregroundColor={FOREGROUND_COLOR} current="Projects" />


        <Gutter h={50} />

        {
          _.map(projects, project => {
            return <Project {...project} key={project.id} backgroundColor={BACKGROUND_COLOR} color={FOREGROUND_COLOR} />
          })
        }

        
      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowHeight: state.windowHeight
  };
}

export default connect(mapStateToProps)(Projects);