import React, { Component } from 'react';
import { connect } from 'react-redux';
import Home from './pages/Home';
import {
  BrowserRouter as Router
} from "react-router-dom";

const Fragment = React.Fragment;

class App extends Component {

  render() {

    return (
      <Router>
        <Home />
      </Router>

    );
  }
}

let mapStateToProps = state => {
  return {
    width: state.windowWidth,
    height: state.windowHeight
  }
};

export default connect(mapStateToProps)(App);
