import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Container, Gutter, ColumnContainer, Title } from '../stylesheets/components';
import { Header, PublicationLists, NewsArticle } from '../components';
import styled from 'styled-components';
import { API_URL } from '../constants/defaults'
import _ from 'lodash';
import media from '../stylesheets/media';

const BACKGROUND_COLOR = "#1F1E20";
const FOREGROUND_COLOR = "#FFFFFF";

const PublicationArea = styled.div`

  width: calc(50% - 20px);

  div.wrapper {
/* 
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; */

  }

  ${media.mobileLarge`
    width: 100%;
  `}
`;

class Publications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      publications: []
    };
  }
  componentDidMount() {
    this.loadData();
  }

  async loadData() {

    const response = await fetch(`${API_URL}/publications?public=true&_sort=news_date:DESC`, { method: "get" });
    if (response.ok) {
      let data = await response.json();
      this.setState({
        publications: data
      });

    } else {
      let data = await response.json();
      console.log(data);
    }
  }

  render() {
    let { publications } = this.state;

    let newsArticles = _.filter(publications, p => p.category === "press");
    let publicationsWithoutNews = _.filter(publications, p => p.category !== "press");
    let groups = _.groupBy(publicationsWithoutNews, publication => publication.category);

    return (
      <Container style={{ backgroundColor: BACKGROUND_COLOR }}>
        <a name="publications"></a>
        <Header backgroundColor={BACKGROUND_COLOR} foregroundColor={FOREGROUND_COLOR} current="Publications" />

        <Gutter h={50} />
        <ColumnContainer>
          <PublicationArea>
            <Title color={FOREGROUND_COLOR}>
              Publications
            </Title>
            <div className="wrapper">
              {
                _.map(groups, (publications, category) => {
                  
                  return (
                    <PublicationLists key={category} foregroundColor={FOREGROUND_COLOR} publications={publications} category={category} />
                  )
                })
              }
            </div>
          </PublicationArea>
          <PublicationArea>
            <Title color={FOREGROUND_COLOR}>
              News
            </Title>
            {
              _.map(newsArticles, (newsArticle) => {
                
                return (
                  <NewsArticle {...newsArticle} key={newsArticle.id} foregroundColor={FOREGROUND_COLOR}  />
                )
              })
            }
          </PublicationArea>
        </ColumnContainer>
      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowHeight: state.windowHeight
  };
}

export default connect(mapStateToProps)(Publications);