import React, { Component } from 'react'
import { connect } from 'react-redux';
import paper from 'paper';
import { randomBetween } from '../utils';
import styled from 'styled-components';
import { scaleLinear } from 'd3';
import chroma from 'chroma-js';

const CircleCanvas = styled.canvas`
  position: absolute;
  left: 0;
  top: 0;
`;

const COLORS = ["#FFFF00", "#ebff00", "#a5dce4", "#f7c9d5", "#7819a5"];
const LENGTH = 50;

class CircleDrawer extends Component {
  constructor(props){
    super(props);
    
    this.canvasRef = React.createRef();
    this.opacityScale = scaleLinear().domain([0, 1024]).range([0.7, 0.2]).clamp(true);
    this.gradientScale = scaleLinear().domain([0, 1024 / 2, 1024]).range([1.0, 1.0, 0.0]).clamp(true);
  }

  componentDidMount(){
    let canvasCurrent = this.canvasRef.current;

    paper.setup(canvasCurrent);
    this.project = paper.View._viewsById[canvasCurrent.id]._project;
    this.view = paper.View._viewsById[canvasCurrent.id];
    
    this.view.draw();
    // this.initColor();
    this.initDraw();
    this.circles = [];
  }


  initDraw(){
    let { windowHeight, windowWidth } = this.props;
    let colorScale = chroma.scale(COLORS).domain([0, LENGTH]);
    var idx = 0;

    this.view.onMouseMove = (event) => {
      let { windowHeight, windowWidth } = this.props;
      this.opacityScale.domain([0, windowWidth]);
      this.gradientScale.domain([0, windowWidth * 0.3, windowWidth]);

      var radius = 200;

      // if (event.delta) {
      //   radius = event.delta.length * 2;
      // } else {
      //   radius = 50;
      // }
      var circle = new paper.Path.Circle(event.point, radius);
      
      let color = colorScale(idx % LENGTH).hex();
      
      circle.opacity = this.opacityScale(circle.position.x);
      circle.fillColor = {
        gradient: {
          stops: [[color, 0.0], [color, this.gradientScale(circle.position.x)], ['rgba(255, 255, 255, 0)', 1.0]],
          radial: true
        },
        origin: circle.position,
        destination: circle.bounds.rightCenter
      };
    
      paper.view.draw();
      this.circles.push(circle);

      if (this.circles.length > LENGTH) {
        let removeCircle = this.circles[0];
        removeCircle.remove();
        this.circles.shift();
      }

      idx++;
    }
    
  }

  componentDidUpdate(){
    let { windowWidth, windowHeight } = this.props;

    let height = Math.max(820, windowHeight);
    this.view.viewSize = new paper.Size( windowWidth, height );
  }

  render() {

    let { windowWidth, windowHeight } = this.props;

    
    let height = Math.max(820, windowHeight);
    return (
      <CircleCanvas id="circledraw" ref={this.canvasRef} width={windowWidth} height={height} style={{ width: windowWidth, height: height}}>
        
      </CircleCanvas>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    windowHeight: state.windowHeight
  }
}

export default connect(mapStateToProps)(CircleDrawer);