import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Logo } from './';
import styled from 'styled-components';
import { TweenMax, Power3 } from 'gsap';
import media from '../stylesheets/media';
import chroma from 'chroma-js';
import { Gutter, Title } from '../stylesheets/components';
import { withRouter } from "react-router-dom";
import { toSlug } from '../utils';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: sticky;
  top: 0;
  padding: 10px 0 15px 0;
  margin-bottom: 20px;
  z-index:5;
  border-bottom: ${props => props.menuOpen ? `1px solid ${props.foregroundColor}` : 'none' };

  ${media.mobileLarge`

    height: ${props => props.menuOpen ? `auto` : '43px;' }; 

  `}
`;

const SplitArea = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(50% - 20px);

  ${media.mobileLarge`
    flex-direction: row-reverse;
    justify-content: flex-start;
  `}
`;

const CurrentIndicator = styled(Title)`
  font-size: 40px;

  ${media.bigDesktop`
    font-size: 30px;
  `}

  ${media.mobileLarge`
    display: none;
  `}
`;

const LogoArea = styled.div`
  width: calc(50% - 20px);

  ${media.mobileLarge`
    transform: scale(0.5);
    transform-origin: left top;
  `}
`;

const DescArea = styled.a`
  text-decoration: underline;
  text-decoration-color: ${props => chroma(props.foregroundColor).alpha(0.5).css()};
  margin-top: 10px;
  ${media.mobileLarge`
    font-size:0.9em;
  `}

`;

const MenuArea = styled.div`
  width: calc(50% - 20px);
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
`;


const Button = styled.button`
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  text-decoration:underline;
  padding: 0;
  margin: 0;
  display: inline;
  cursor: pointer;
  color: ${props => props.color};
  text-decoration-color: ${props => chroma(props.color).alpha(0.5).css()};
  font-size: 18px;
  transition: 0.4s all;

  ${media.mobileLarge`
    font-size:0.9em;
  `}
  &:hover {
    opacity: 0.6;
  }

  
`;

const Fragment = React.Fragment;

class Header extends Component {
  constructor(props){
    super(props);

    this.state = {
      menuOpen: false
    }
  }
  handleMenuClick(e){
    this.setState({
      menuOpen: !this.state.menuOpen
    })
    
  }

  handleHomeClick(e){
    let { realLink } = this.props;

    if (realLink) { 

      document.location.href = `/`;

    } else {
        
      
      this.setState({
        menuOpen: false
      });
      this.moveScroll(0);
      this.props.history.push(`/`);

    }

  }

  handleClick(e){
    let { realLink } = this.props;

    if (realLink) { 

      document.location.href = `/#${e}`;

    } else {
        
      this.setState({
        menuOpen: false
      }, () => {

        var d = document.querySelector(`a[name=${e}]`);

        var bodyRect = document.body.getBoundingClientRect(),
            elemRect = d.getBoundingClientRect(),
            pos = elemRect.top - bodyRect.top;

            
        this.moveScroll(pos);
      });
      // this.props.history.push(`/${e}`);
    }
  }

  moveScroll(pos) {
    TweenMax.to((document.scrollingElement || document.documentElement), 1, { ease: Power3.easeInOut, scrollTop: pos - 10});
  }

  render() {
    let { current, foregroundColor, backgroundColor } = this.props;
    let { menuOpen } = this.state;
    return (
      <Fragment>
        <HeaderContainer menuOpen={menuOpen} foregroundColor={foregroundColor} style={{ background: backgroundColor }}>
          <LogoArea>
            <a href="javascript:void(0);" onClick={this.handleHomeClick.bind(this)}>
              <Logo color={foregroundColor} />
            </a>
          </LogoArea>
          
          {
            menuOpen ? 
            <MenuArea>
              <div>
                <Button color={foregroundColor} onClick={this.handleClick.bind(this, "about-the-lab")}>About the lab</Button><br />
                <Button color={foregroundColor} onClick={this.handleClick.bind(this, "people")}>People</Button><br />
                <Button color={foregroundColor} onClick={this.handleClick.bind(this, "projects")}>Projects</Button><br />
                <Button color={foregroundColor} onClick={this.handleClick.bind(this, "events")}>Events</Button><br />
                <Button color={foregroundColor} onClick={this.handleClick.bind(this, "courses")}>Courses</Button><br />
                <Button color={foregroundColor} onClick={this.handleClick.bind(this, "publications")}>Publications</Button><br/>
                <Button color={foregroundColor} onClick={this.handleClick.bind(this, "blog")}>Blog</Button><br/>
                <Button color={foregroundColor} onClick={this.handleClick.bind(this, "links")}>Inspirations</Button>
              </div>
              <div>
                <a href="javascript:void(0)" onClick={this.handleMenuClick.bind(this)}>
                  <svg width="29" height="29" viewBox="0 0 29 29" fill="none">
                    <path d="M28 1L0.999999 28" stroke={foregroundColor} strokeWidth="2" />
                    <path d="M28 28L1 0.999997" stroke={foregroundColor} strokeWidth="2" />
                  </svg>
                </a>
              </div>
            </MenuArea> :
            <SplitArea>

              <CurrentIndicator style={{ color: foregroundColor }}>
                {current}
              </CurrentIndicator>
              <DescArea href="javascript:void(0);" foregroundColor={foregroundColor} onClick={this.handleMenuClick.bind(this)}>
                <svg width="23" height="20" viewBox="0 0 23 20" fill="none">
                  <path d="M0 2H23" stroke={foregroundColor} strokeWidth="2"/>
                  <path d="M0 10H23" stroke={foregroundColor} strokeWidth="2"/>
                  <path d="M0 18H23" stroke={foregroundColor} strokeWidth="2"/>
                </svg>
              </DescArea>
            </SplitArea>
          }
        </HeaderContainer>
        {
          menuOpen ?
          <Gutter h={20} />
          : null
        }
      </Fragment>
    
    )
  }
}

let mapStateToProps = state => {
  return {

  }
}

export default withRouter(connect(mapStateToProps)(Header));