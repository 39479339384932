import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Container, Gutter, ColumnContainer, Title } from '../stylesheets/components';
import { Header, PublicationLists, NewsArticle } from '../components';
import styled from 'styled-components';
import { API_URL } from '../constants/defaults'
import _ from 'lodash';
import media from '../stylesheets/media';
import moment from 'moment';

const BACKGROUND_COLOR = "#FFFFFF";
const FOREGROUND_COLOR = "#1F1E20";

const PublicationArea = styled.div`

  width: calc(50% - 20px);

  div.wrapper {

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

  }

  ${media.mobileLarge`
    width: 100%;
  `}
`;

const Article = styled.a`
  border-top: 1px solid ${FOREGROUND_COLOR};
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:last-child {
    border-bottom: 1px solid ${FOREGROUND_COLOR};
  }
`;

const ArticleTitle = styled.div`
  color: ${FOREGROUND_COLOR};
  width: calc(50vw + 20px);
`;


const ArticleAuthor = styled.div`
  color: ${FOREGROUND_COLOR};
  width: 30%;
`;


const ArticleDate = styled.div`
  color: ${FOREGROUND_COLOR};
  width: 20%;
  text-align: right;
`;

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogArticles: null
    };
  }
  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    const response = await fetch(`https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/data-feminism-lab-mit/`, { method: "get" });
    if (response.ok) {
      let data = await response.json();
      this.setState({
        blogArticles: data
      });

      console.log(data);

    } else {
      let data = await response.json();
      console.log(data);
    }

    
  }

  render() {
    let { blogArticles } = this.state;
    
    return (
      <Container style={{ backgroundColor: BACKGROUND_COLOR }}>
        <a name="blog"></a>
        <Header backgroundColor={BACKGROUND_COLOR} foregroundColor={FOREGROUND_COLOR} current="Blog" />

        <Gutter h={50} />
        {
          blogArticles ?
          (blogArticles.items.length > 0 ? 
          _.map(blogArticles.items, article => {
            return (
              <Article href={article.link} key={article.guid} target="_blank" rel="noopener noreferrer">
                <ArticleTitle>
                  { article.title }
                </ArticleTitle>
                <ArticleAuthor>
                  { article.author }
                </ArticleAuthor>
                <ArticleDate>
                  { moment(article.pubDate).format("MMMM Do YYYY") }
                </ArticleDate>
              </Article>
            )
          }) : 
          <Article>
            <ArticleTitle>
              No articles to show.
            </ArticleTitle>
          </Article>) : null
        }
      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowHeight: state.windowHeight
  };
}

export default connect(mapStateToProps)(Blog);