import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Event } from '../components';
import { Container, Gutter } from '../stylesheets/components';
import { Header } from '../components'
import { ColumnContainer, Column } from '../stylesheets/components';
import { API_URL } from '../constants/defaults'
import { withRouter } from "react-router-dom";
const BACKGROUND_COLOR = "#EFF8F3";
const FOREGROUND_COLOR = "#BF223C";

class EventShow extends Component {
  constructor(props){
    super(props);
    this.state = {
      event: []
    };
  }
  componentDidMount(){
    this.loadData();
  }

  async loadData() {
    const response = await fetch(`${API_URL}/events/${this.props.match.params.id}`, { method: "get" });
    if (response.ok) {
      let data = await response.json();
      this.setState({
        event: data
      });

    } else {
      let data = await response.json();
      console.log(data);
    }
  }

  render() {
    let { event } = this.state;
    return (
      <Container style={{ backgroundColor: BACKGROUND_COLOR }}>
        <Header backgroundColor={BACKGROUND_COLOR} foregroundColor={FOREGROUND_COLOR} current="Events" realLink={true} />
        <Gutter h={75} />
        <Event isAlwaysOpen={true} {...event} backgroundColor={BACKGROUND_COLOR} color={FOREGROUND_COLOR} />

      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowHeight: state.windowHeight
  };
}

export default withRouter(connect(mapStateToProps)(EventShow));