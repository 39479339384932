import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Container, Gutter } from '../stylesheets/components';
import styled from 'styled-components';
import { changeAboutContent } from "../actions";
import { Header } from '../components'
import { TITLE_TYPE } from '../stylesheets/mixins';
import { scaleLinear } from 'd3';
import { ColumnContainer, Column } from '../stylesheets/components';
import { API_URL } from '../constants/defaults';
import ReactMarkdown from 'react-markdown';
import media from '../stylesheets/media';

const BACKGROUND_COLOR = "#EBFF00";
const FOREGROUND_COLOR = "#F83C50";

const Slogan = styled.div`
  p {
    ${TITLE_TYPE}
    color: ${props => props.color};

    max-width: 800px;
    font-size:2.9em;

    ${media.bigDesktop`
      max-width: 600px;
      font-size:2.5em;
    `}

    ${media.mobileLarge`
      font-size: 1.7em;
    `}
    em {
      font-style: italic;
    }
  }
`;

const Description = styled.div`
  p {
    color: ${ props => props.color};
    margin-bottom: 20px;
    
    max-width: 700px;
    font-size: 1.2em;
    line-height: 1.3;
    
    ${media.bigDesktop`
      max-width: 600px;
      font-size: 1.0em;
      line-height: 1.3;
    `}

    ${media.mobileLarge`
      font-size: 1.0em;
    `}
  }
  a {
    color: ${ props => props.color};
    text-decoration:underline;
  }
  
`;

class About extends Component {

  constructor(props){
    super(props);
    this.state = {
      aboutContent: {

      }
    };
  }
  componentDidMount(){
    this.loadData();
  }

  async loadData() {

    const response = await fetch(`${API_URL}/about-the-labs`, { method: "get" });
    if (response.ok) {
      let data = await response.json();
      this.props.dispatch(changeAboutContent(data[0]));

    } else {
      let data = await response.json();
      console.log(data);
    }
  }

  render() {
    let { aboutContent } = this.props;

    return (
      <Container style={{ backgroundColor: BACKGROUND_COLOR}}>
        <a name="about-the-lab"></a>

        
        <Header backgroundColor={BACKGROUND_COLOR} foregroundColor={FOREGROUND_COLOR} current="About the lab" />
        <Gutter h={75} />
        <ColumnContainer>
          <Column>
          </Column>

          <Column color={FOREGROUND_COLOR}>
            <Slogan color={FOREGROUND_COLOR}>
              <ReactMarkdown linkTarget='_blank' source={aboutContent.bigtext} />
            </Slogan>
            <Gutter h={50} />
            <Description color={FOREGROUND_COLOR}>

              <ReactMarkdown linkTarget='_blank' source={aboutContent.description} />
            </Description>
          </Column>
        </ColumnContainer>

      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowHeight: state.windowHeight,
    aboutContent: state.aboutContent
  };
}

export default connect(mapStateToProps)(About);