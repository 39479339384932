import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Event } from '../components';
import { Container, Gutter, ColumnContainer, Column } from '../stylesheets/components';
import { Header } from '../components'
import { Title } from '../stylesheets/components';
import { API_URL } from '../constants/defaults'
import _ from 'lodash';
import moment from 'moment';

const BACKGROUND_COLOR = "#EFF8F3";
const FOREGROUND_COLOR = "#BF223C";

class Events extends Component {
  constructor(props){
    super(props);
    this.state = {
      events: []
    };
  }
  componentDidMount(){
    this.loadData();
  }

  async loadData() {
    
    const response = await fetch(`${API_URL}/events?public=true&_sort=event_start_at:DESC`, { method: "get" });
    if (response.ok) {
      let data = await response.json();
      this.setState({
        events: data
      });

    } else {
      let data = await response.json();
      console.log(data);
    }
  }

  render() {
    let { events } = this.state;

    let futureEvents = _.filter(events, ev => {
      return moment(ev.event_end_at).isAfter(moment());
    })


    let pastEvents = _.filter(events, ev => {
      return !moment(ev.event_end_at).isAfter(moment());
    })

    return (
      <Container style={{ backgroundColor: BACKGROUND_COLOR }}>
        <a name="events"></a>
        <Header backgroundColor={BACKGROUND_COLOR} foregroundColor={FOREGROUND_COLOR} current="Events" />
        <Gutter h={80} />
        <ColumnContainer>
          <Column>
            <Title color={FOREGROUND_COLOR}>
              Upcoming Events<br/>
              -
            </Title>
            {
              _.map(futureEvents, event => {
                return <Event isAlwaysOpen={true} {...event} key={event.id} backgroundColor={BACKGROUND_COLOR} color={FOREGROUND_COLOR} />
              })
            }
          </Column>
          <Column>
            <Title color={FOREGROUND_COLOR}>
              Past Events<br/>
              -
            </Title>
            {
              _.map(pastEvents, event => {
                return <Event isAlwaysOpen={false} {...event} key={event.id} backgroundColor={BACKGROUND_COLOR} color={FOREGROUND_COLOR} />
              })
            }
          </Column>
        </ColumnContainer>
       


      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowHeight: state.windowHeight
  };
}

export default connect(mapStateToProps)(Events);