import styled from 'styled-components';
import { TITLE_TYPE } from './mixins';
import media from './media';
import chroma from 'chroma-js';
import {
  Link
} from "react-router-dom";

const ColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.mobileLarge`
    display: block;
  `}
`;

const Column = styled.div`
  width: calc(50% - 20px);
  p.text {
    color: ${ props => props.color};
    max-width: 600px;
    margin-bottom: 20px;
  }


  ${media.mobileLarge`
    width: 100%;
  `}
`;

const Container = styled.div`
  padding: 10px 20px;
  min-height: calc(100vh - 20px);
  position: relative;
`;


const Gutter = styled.div`
  height: ${props => props.h}px;
`;
const TitleA = styled(Link)`
  ${TITLE_TYPE}
  font-size: 40px;
  color: ${props => props.color};
  margin-bottom: 10px;

  ${media.bigDesktop`
    font-size: 35px;
  `}
`;

const Title = styled.h2`
  ${TITLE_TYPE}
  font-size: 40px;
  color: ${props => props.color};
  margin-bottom: 10px;

  ${media.bigDesktop`
    font-size: 35px;
  `}
`;

const Description = styled.div`
  color: ${props => props.color};
  max-width: 700px;
  font-size: 20px;
  line-height: 1.3;

  ${media.bigDesktop`
    max-width: 500px;
    font-size: 17px;
    line-height: 1.3;
  `}
  

  p {
    color: ${props => props.color};
    margin-bottom: 10px;
  }

  a {
    color: ${props => props.color};
    text-decoration: underline;
    text-decoration-color: ${ props => props.color ? chroma(props.color).alpha(0.4).css() : "black" };
    text-decoration-thickness: 1px;

  }

  ul {
    list-style-type: circle;
    margin-left: 20px;
    li {
      margin-bottom: 2px;
    }
  }
`;


const Credit = styled(Description)`
  font-size: 0.8em;
  margin-top: 5px;

  ${media.bigDesktop`
    font-size: 0.8em;
  `}
`;


export {
  ColumnContainer,
  Column,
  Container,
  Gutter,
  Title,
  TitleA,
  Description,
  Credit
};