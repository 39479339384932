import React, { Component } from 'react'
import styled from 'styled-components';
import { ColumnContainer, Column, Gutter, Title, Description } from '../stylesheets/components';
import ReactMarkdown from 'react-markdown';
import { API_URL } from '../constants/defaults';
import media from '../stylesheets/media';
const Affi = styled.div`
  font-size: 17px;
  color: ${props => props.color};
`;


const PersonColumnContainer = styled(ColumnContainer)`
  margin-bottom: 100px;
`

const HeadshotContainer = styled.div`
  background-color: ${props => props.backgroundColor};
  width: 400px;
  border-radius:400px;
  overflow:hidden;


  ${media.bigDesktop`
    width: 300px;
    border-radius:300px;
  `}
`;

const Headshot = styled.img`

    width: 400px;
    border-radius:400px;
  transition: 0.5s all;
  
  mix-blend-mode: multiply;
  -webkit-filter: grayscale(100%) contrast(1.2);
  filter: grayscale(100%) contrast(1);

  ::after{
    background-color: #23278a;
    mix-blend-mode: lighten;
  }

  &:hover {
    filter: none;
    -webkit-filter: none;
    mix-blend-mode: unset !important;
    background: transparent;
  }

  ${media.bigDesktop`
    width: 300px;
    border-radius:300px;
  `}
`;


export default class Person extends Component {
  render() {
    return (
      <PersonColumnContainer>
        <Column>
          <Title color={this.props.color} style={{ marginBottom: 0}}>
            {this.props.first_name} {this.props.last_name }
          </Title>
          <Affi color={this.props.color}>
            {this.props.position}
          </Affi>
          <Gutter h={30} />
          <Description color={this.props.color}>
            <ReactMarkdown linkTarget='_blank' source={this.props.bio} />
          </Description>
        </Column>

        <Column>
          {
            this.props.headshot ? 
            <HeadshotContainer backgroundColor={this.props.backgroundColor}>
              <Headshot color={this.props.color} src={`${API_URL}${this.props.headshot.url}`} alt={`A headshot of ${this.props.first_name}`} />
            </HeadshotContainer> : null
          }
          
        </Column>

      </PersonColumnContainer>
    )
  }
}
