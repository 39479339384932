import { DEFAULT_STATE } from '../constants/defaults';

export default function screenReducer(state = DEFAULT_STATE, action){
  switch(action.type) {
    case "WINDOW_RESIZE":
      return {
        ...state,
        windowWidth: action.payload.windowWidth,
        windowHeight: action.payload.windowHeight
      }
    case "CHANGE_CURRENT_EVENT":
      return {
        ...state,
        currentEvent: action.payload.currentEvent
      }
    case "CHANGE_SELECTED_PERSON":
      return {
        ...state,
        selectedPerson: action.payload.selectedPerson
      }
    case "CHANGE_ABOUT_CONTENT":
      return {
        ...state,
        aboutContent: action.payload.aboutContent
      };
    default: 
      return {
        ...state
      };
  }

};


