import React, { Component } from 'react'
import styled from 'styled-components';
const Affi = styled.div`
  font-size: 17px;
  color: ${props => props.color};
`;

const Container = styled.div`
  margin-right: 15px;
`;


export default class PastPerson extends Component {
  render() {
    return (
      <Container>
        <Affi color={this.props.color}>
          {this.props.first_name} {this.props.last_name }
        </Affi>
      </Container>  
    )
  }
}
