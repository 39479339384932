import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Person, PastPerson } from '../components';
import { Container, Gutter } from '../stylesheets/components';
import { Header } from '../components'
import { Title, Description } from '../stylesheets/components';
import { API_URL } from '../constants/defaults'
import _ from 'lodash';
import ReactMarkdown from 'react-markdown';
import media from '../stylesheets/media';
import styled from 'styled-components';
import { changeSelectedPerson } from '../actions';

const BACKGROUND_COLOR = "#A5DCE4";
const FOREGROUND_COLOR = "#690018";
const Fragment = React.Fragment;
const EmptyHeadshot = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.width}px;
  background-color: #7ca8ae;
  transition: 0.5s all;
  border-radius: ${props => props.width}px;
`;



const Article = styled.div`
  cursor:pointer;
  display: block;
  border-top: 1px solid ${FOREGROUND_COLOR};
  padding: 8px 0;

  &:last-child {
    border-bottom: 1px solid ${FOREGROUND_COLOR};
  }

  &.selected {
    cursor: auto;
    &:hover {
      opacity: 1;
    }
  }
`;


const ArticleWrapper = styled.div`

  display: flex;
  align-items: ${props => props.mode === "expanded" ? "flex-start" : "center"};
  justify-content: space-between;

  ${media.mobileSmall`
    display: block;
  `}
`;

const PersonPosition = styled.span`

${media.mobileSmall`
    display: none;
  `}
`;

const ArticleTitle = styled.div`
  color: ${FOREGROUND_COLOR};
  width: calc(30vw + 20px);
  display: flex;
  align-items: center;
  font-size: 20px;
  ${media.mobileSmall`
    width: 100%;
  `}
  ${media.bigDesktop`
    font-size: 17px;
  `}
  
`;


const ArticleAuthor = styled.div`
  color: ${FOREGROUND_COLOR};
  width: 55%;
  font-size: 20px;

  ${media.bigDesktop`
    font-size: 17px;
  `}

  ${media.mobileSmall`
    width: 100%;
  `}

`;


const ArticleDate = styled.div`
  color: ${FOREGROUND_COLOR};
  width: 15%;
  text-align: right;
  font-size: 20px;

  ${media.bigDesktop`
    font-size: 17px;
  `}

  ${media.mobileSmall`
    display: none;
  `}
`;

const ArticleName = styled.div`

  color: ${FOREGROUND_COLOR};
  margin-left: 10px;
`;

const Headshot = styled.img`

  width: ${props => props.width}px;
  transition: 0.5s all;
  border-radius: ${props => props.width}px;
  
  &.collapsed {
    mix-blend-mode: multiply;
    -webkit-filter: grayscale(100%) contrast(1.2);
    filter: grayscale(100%) contrast(1);
  }
  
  &.expanded {

    filter: none;
    -webkit-filter: none;
    mix-blend-mode: unset !important;
    background: transparent;

    ${media.mobileSmall`
      margin-bottom: 10px;
    `}
  }

  ::after{
    background-color: #23278a;
    mix-blend-mode: lighten;
  }

  &:hover {
    filter: none;
    -webkit-filter: none;
    mix-blend-mode: unset !important;
    background: transparent;
  }

`;
const ArticleAuthorPositionArea = styled.div`
  margin-bottom: 15px;

  div {
    color: ${FOREGROUND_COLOR};
  }

  div.pos {
    text-indent: 30px;
  }
`

class People extends Component {
  constructor(props){
    super(props);
    this.state = {
      people: []
    };
  }

  handleClick(personId){
    this.props.dispatch(changeSelectedPerson(personId));
  }


  componentDidMount(){
    this.loadData();
  }

  async loadData() {
    
    const response = await fetch(`${API_URL}/people?public=true&_sort=rank:DESC,last_name:ASC`, { method: "get" });
    if (response.ok) {
      let data = await response.json();
      this.setState({
        people: data
      });

    } else {
      let data = await response.json();
      console.log(data);
    }
  }

  renderPerson(person) {
    let { selectedPerson } = this.props;
    return (
      <Article key={person.id} className={selectedPerson === person.id ? "selected" : ""} onClick={this.handleClick.bind(this, person.id)}>
        <ArticleWrapper mode={selectedPerson === person.id ? "expanded" : "collapsed"}>

        {
            selectedPerson === person.id ?   
            <Fragment>
              <ArticleTitle>
                {
                  person.headshot ?
                  <Headshot width={170} className="expanded" color={FOREGROUND_COLOR} src={`${API_URL}${person.headshot.url}`} alt={`A headshot of ${person.first_name}`} /> :
                  <EmptyHeadshot width={170} />
                }
              </ArticleTitle>
            </Fragment> : 
            <Fragment>
              <ArticleTitle>
                {
                  person.headshot ?
                  <Headshot width={50} className="collapsed" color={FOREGROUND_COLOR} src={`${API_URL}${person.headshot.url}`} alt={`A headshot of ${person.first_name}`} /> :
                  <EmptyHeadshot width={50} />
                }
              
                <ArticleName>
                  { person.first_name } { person.last_name }
                </ArticleName>
              </ArticleTitle>
            </Fragment>  
          }
          <ArticleAuthor>
            {
              selectedPerson === person.id ?   
              <Fragment>                              
                <ArticleAuthorPositionArea>
                  <div>
                    { person.first_name } { person.last_name }
                  </div>
                  <div className="pos">
                    { person.position }
                  </div>
                </ArticleAuthorPositionArea>
              </Fragment> : 
              <PersonPosition>
                { person.position }
              </PersonPosition>
            }
            {
              selectedPerson === person.id ?   
              <Fragment>
                <Description color={FOREGROUND_COLOR}>
                  <ReactMarkdown linkTarget='_blank' source={person.bio} />
                </Description> 
              </Fragment> : null
            }
          </ArticleAuthor>
          <ArticleDate>
            { person.affiliation }
          </ArticleDate>
        </ArticleWrapper>
      </Article>
    )
  }

  render() {
    let { people } = this.state;

    let director = _.find(people, p => p.rank === 5);
    let restPeople = [ ...people ];
    restPeople.shift();

    let currentPeople = _.filter(restPeople, p => p.current);
    let pastPeople = _.filter(restPeople, p => !p.current);

    let { selectedPerson } = this.props;

    return (
      <Container style={{ backgroundColor: BACKGROUND_COLOR }}>
        <a name="people"></a>
        <Header backgroundColor={BACKGROUND_COLOR} foregroundColor={FOREGROUND_COLOR} current="People" />
        <Gutter h={75} />
        
        {
          director ? 
          <Person {...director} key={director.id} backgroundColor={BACKGROUND_COLOR} color={FOREGROUND_COLOR} /> : null
        }
        
        <Title color={FOREGROUND_COLOR}>
          Current Members
        </Title>

        {
          currentPeople.length > 0 ?
          <div>
             {
                _.map(currentPeople, person => {
                  return this.renderPerson(person);
                }) 
              }
          </div> : null
        }
        
        <Gutter h={50} />
        <Title color={FOREGROUND_COLOR}>
          Alumni
        </Title>
        {
          pastPeople.length > 0 ?
          <div>
             {
                _.map(pastPeople, person => {
                  return this.renderPerson(person);
                }) 
              }
          </div> : null
        }
        <Gutter h={100} />

      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowHeight: state.windowHeight,
    selectedPerson: state.selectedPerson
  };
}

export default connect(mapStateToProps)(People);